@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Rubik:wght@400&display=swap');

$primary: #5928EB;
$link: #44464B;
$danger: #FF6174;
$warning: #FFDA58;
$success: #10DC6E;
$family-sans-serif: "Nunito Sans", sans-serif;
$radius: 5px;
$radius-small: 5px;
$radius-large: 5px;
$tag-radius: 5px;
$modal-background-background-color: rgba(35, 25, 50, 0.7);
$modal-card-head-radius: 5px;
$modal-card-foot-radius: 5px;
$modal-card-title-size: 1.2rem;


@import "~bulma/bulma";

@import "tippy.js/dist/tippy.css";

html {
    background-color: #f3f0ff;
}

.tippy-box[data-theme~="riskscribe"] {
    background-color: #646464;
    color: white;
}

.button {
    min-width: 10rem
}

.font-rubik {
    font-family: 'Rubik', sans-serif;
}

.navbar .prevent-active:not(:hover) {
    background-color: initial !important;
}

.button.new-assessment {
    background-color: #B8411A;
    color: white;
    border: none;
}

.new-assessment:hover {
    background-color: #9E3814;
}

@import '@fortawesome/fontawesome-free/css/all.min.css';
@import "react-quill/dist/quill.snow.css";
@import "react-datepicker/dist/react-datepicker.css";

@import "assessment-layout.css";
@import "assessment-lists.css";
@import "custom-dropdown.css";