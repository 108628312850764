.list-items {
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr auto auto auto;
}

.list-items:hover:not(.highlight) {
  background-color: #f3f0ff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
}

.objectives-panel .icon,
.risks-list-panel .icon,
.controls-panel .icon {
  transition: transform 0.3s, opacity 0.3s;
}

.objectives-panel .icon:hover,
.risks-list-panel .icon:hover,
.controls-panel .icon:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

.highlight {
  background-color: #ebe5ff;
}

.risks-list-panel .tag {
  min-width: 10rem;
}
