.custom-dropdown {
  position: relative;
  width: auto;
  display: inline-block;
  border: 1px solid #e0e0e0;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
  min-width: 10rem;
}

.custom-dropdown:hover {
  border-color: #a0a0a0;
}

.custom-dropdown-selected-option {
  font-size: 1rem;
}

.custom-dropdown-options-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  min-width: inherit;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
}

.custom-dropdown-option {
  padding: 0.2rem 1rem;
  font-size: 1rem;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.custom-dropdown-option:last-child {
  border-bottom: none;
}

.custom-dropdown-option:hover {
  background-color: #f5f5f5;
}
