.assessment-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: calc(100vh - 3.5rem);
  gap: 1rem;
  padding: 1rem;
}

.objectives-panel {
  grid-column: 1;
  background-color: white;
  padding: 1rem;
  display: grid;
  grid-template-areas:
    "objective-title"
    "header"
    "content";
  grid-template-rows: auto auto 1fr;
  min-height: 0;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.risks-container {
  grid-column: 2;
  display: grid;
  grid-template-rows: 1.5fr 0.5fr 1fr;
  min-height: 0;
  gap: 1rem;
}

.risks-list-panel {
  display: grid;
  background-color: white;
  padding: 1rem;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto 1fr;
  min-height: 0;
  grid-row: 1;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.risk-details-panel {
  display: grid;
  background-color: white;
  padding: 1rem;
  grid-template-areas:
    "header header"
    "risk-inherent risk-residual";
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;
  grid-row: 2;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  min-height: 12rem;
}

.risk-details-panel .header {
  line-height: 1.5rem;
  vertical-align: top;
}

.exposure-panel {
  display: grid;
  grid-template-areas:
    "exposure-header exposure"
    "probability-label probability-selector"
    "impact-label impact-selector";
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.probability-label {
  grid-area: probability-label;
}

.probability-selector {
  grid-area: probability-selector;
}

.impact-label {
  grid-area: impact-label;
}

.impact-selector {
  grid-area: impact-selector;
}

.exposure {
  grid-area: exposure;
}

.exposure-header {
  display: grid;
  font-weight: bold;
  margin-bottom: 5px;
}

.controls-panel {
  grid-row: 3;
  background-color: white;
  padding: 1rem;
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto 1fr;
  min-height: 0;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.risk-inherent {
  grid-area: risk-inherent;
}

.risk-residual {
  grid-area: risk-residual;
}

.header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.objective-title {
  grid-area: objective-title;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #55565b;
  color: white;
}

.content {
  grid-area: content;
  overflow: auto;
}

.assessment-list {
  height: calc(100vh - 3.5rem);
  overflow-y: auto;
}

.assessment-list-item {
  width: 100%;
  padding: 1rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr auto;
}

.assessment-list-item-description {
  grid-row: 1 / 6;
}

.acl-container {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr auto;
  align-items: center;
}

.acl-container-buttons {
  grid-column: span 5;
  display: flex;
  justify-content: space-evenly;
}

.criteria-editor-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: calc(100vh - 3.5rem);
  gap: 1rem;
  padding: 1rem;
}

.criteria-panel {
  grid-column: 1;
  padding: 1rem;
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto 1fr;
  min-height: 0;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.criteria-details {
  min-height: 0px;
  grid-column: 2;
  display: grid;
  grid-template-rows: 4fr 6fr auto;
  gap: 1rem;
}

.criteria-probability,
.criteria-impact {
  padding: 1rem;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.criteria-element {
  display: grid;
  grid-template-columns: 1fr 7fr;
  margin-bottom: 1rem;
  gap: 2rem;
}

.criteria-tolerance {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 1rem;
}

.criteria-heatmap {
  grid-column: span 2;
}

.criteria-exposure,
.criteria-limits {
  display: flex;
  justify-content: center;
  align-items: center;
}

.criteria-exposure input,
.criteria-limits input {
  width: 3rem;
  margin-left: 5px;
  margin-right: 5px;
}

.plan-editor-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: calc(100vh - 3.5rem);
  gap: 1rem;
  padding: 1rem;
}

.action-panel {
  grid-column: 1;
  padding: 1rem;
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto 1fr;
  min-height: 0;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.plan-details {
  min-height: 0px;
  grid-column: 2;
  overflow-y: auto;
  grid-template-rows: auto 1fr;
}

.plan-details .quill-container {
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 1rem;
}

.plan-basics {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 1rem;
}

.report-selector-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}

.report-selector-button {
  grid-column: span 2;
  display: flex;
  justify-content: center;
}

.exposure .tag {
  min-width: 10rem;
}
